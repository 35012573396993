import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from '../FalconCloseButton';


const InfoModal = ({ title, message, show, onHide }) => {

    return (
        <Modal show={show} onHide={() => onHide(false)} keyboard={false}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <FalconCloseButton onClick={() => onHide(false)} />
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InfoModal;
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';

import ErrorLayout from './ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Login from 'components/authentication/card/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
// import Gdpr from 'components/pages/gdpr/gdpr';
// import TermsAndConditions from 'components/pages/terms-and-conditions/terms-and-conditions';
import MainLayout from './MainLayout';
import { UserContext } from 'context/Context';
import Dashboard from 'components/dashboards/default';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import { getLoggedUser } from 'api/auth';
import Analytics from 'components/dashboards/analytics';
import Crm from 'components/dashboards/crm';
import Ecommerce from 'components/dashboards/e-commerce';
import Saas from 'components/dashboards/saas';
import ProjectManagement from 'components/dashboards/project-management';
import Calendar from 'components/app/calendar/Calendar';
import { getMaintenanceStatus } from 'api/settings';
import Leads from 'components/pages/leads/Leads';
import Loading from 'components/utilities/Loading';
import LeadDetails from 'components/pages/leads/LeadDetails';
import Services from 'components/pages/services/Services';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const [isMaintenance, setIsMaintenance] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }

    async function getUser() {
      const result = await getLoggedUser();
      if (result.status === 200) {
        setUser(result.data);
      }
    }

    if (isMaintenance == null) {
      getMaintenance();
    }

    if (!user && localStorage.getItem('isLoggedIn') === 'true') {
      getUser();
    } else {
      setLoading(false);
    }
  }, [HTMLClassList, user, isMaintenance]);

  async function getMaintenance() {
    const result = await getMaintenanceStatus();
    if (result.status === 200) {
      setIsMaintenance(result.data);
    }
  }

  if (loading) {
    return (
      <Loading></Loading>
    )
  }

  return (
    <>
      <UserContext.Provider value={user}>
        <Routes>
          <Route path="/" element={<Landing isMaintenance={isMaintenance} />} />
          {/* <Route path="/gdpr" element={<Gdpr />} /> */}
          {/* <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> */}
          <Route element={<ErrorLayout />}>
            <Route path="errors/404" element={<Error404 />} />
            <Route path="errors/500" element={<Error500 />} />
          </Route>
          {/*- ------------- Authentication ---------------------------  */}

          {/*- ------------- simple ---------------------------  */}

          <Route path="login" element={<Login />} />
          <Route path="logout" element={<SimpleLogout />} />

          {user ? (
            <Route path="dashboard" element={<MainLayout user={user} />}>
              <Route index element={<Dashboard />}></Route>
              <Route path="analytics" element={<Analytics />}></Route>
              <Route path="crm" element={<Crm />}></Route>
              <Route path="e-commerce" element={<Ecommerce />}></Route>
              <Route path="saas" element={<Saas />}></Route>
              <Route path="project-management" element={<ProjectManagement />}></Route>
              <Route path="leads" element={<Leads />}></Route>
              <Route path="calendar" element={<Calendar />}></Route>
              <Route path="services" element={<Services />}></Route>
              <Route path="user/profile" element={<Profile />} />
              <Route path="user/settings" element={<Settings />} />
              <Route path="*" element={<Navigate to="/errors/404" replace />} />
            </Route>
          ) : (
            <Route path="dashboard" element={<Navigate to="/login" replace />}>
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
          )}
          {/* <Navigate to="/errors/404" /> */}
          <Route path="*" element={<Navigate to="/errors/404" replace />} />
        </Routes>
      </UserContext.Provider>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

import illustration1 from 'assets/img/icons/spot-illustrations/16.png';
import illustration2 from 'assets/img/icons/spot-illustrations/calendar.svg';
import illustration3 from 'assets/img/icons/spot-illustrations/navbar-vertical.png';
import illustration4 from 'assets/img/icons/spot-illustrations/envelope.png';
import illustration5 from 'assets/img/icons/spot-illustrations/creating.png';
import illustration6 from 'assets/img/icons/spot-illustrations/editing.svg';
import {
  faBug,
  faPaperPlane,
  faSignature,
  faTools,
  faEnvelope,
  faHandshake
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default [
  {
    icon: faEnvelope,
    iconText: 'HAI SĂ NE CUNOAȘTEM',
    color: 'danger',
    title: 'Contacteză-ne',
    description:
      'Completează formularul de contact și nu uita să ne prezinți ideea afacerii tale online, sau detaliile despre proiectul tău deja existent.',
    image: illustration1,
    children: 
      <Button
        id="contact-btn"
        variant="outline-danger"
        className="mb-4 fs--1 border-2 rounded-pill"
        onClick={() => {
          var element = document.getElementById("contact");
          element.scrollIntoView({
            behavior:"smooth",
            block: "start"
          })
        }}
        >
          <span className="me-2" role="img" aria-label="phone">
          ☎
          </span>
          Contactează-ne!
      </Button>
  },
  {
    icon: faHandshake,
    iconText: 'PRIMA ÎNTÂLNIRE 👔',
    color: 'info',
    title: 'Consultanță Gratuită',
    description:
      'Programarea unei întâlniri unde vom verifica proiectul actual, dacă există, sau ideea afacerii tale online. Vom veni cu soluții/idei de actualitate și vom face o estimare de cost și timp de integrare.',
    image: illustration2,
    inverse: true
  },
  {
    icon: faSignature,
    iconText: 'ÎNCEPEM COLABORAREA 🚀',
    color: 'warning',
    title: 'Planificare și semnarea contractului',
    description:
      'Începem tot procesul de colaborare prin planificarea detaliată a proiectului tău sau a funcționalităților dorite. O dată ce se acceptă soluțiile prezentate, vom semna un contract prin care ne asumăm implementarea dorită.',
    image: illustration4 
  },
  {
    icon: faTools,
    iconText: 'GATA CU PAUZELE DE CAFEA ☕',
    color: 'success',
    title: 'Implementare propriu-zisă',
    description:
      'Începem construirea proiectului și întâlnirile săptămânale prin care vă prezentăm progresul și revizuirea acestuia de către responsabilul din firma ta. Rapoarte o data la două săptămâni unde vom prezenta rezumatul.',
    image: illustration5,
    inverse: true
  },
  {
    icon: faBug,
    iconText: 'ÎNCEPEM DEPARAZITAREA 🔫',
    color: 'danger',
    title: 'Testarea website-ului ',
    description:
      'Rezolvarea problemelor și cererilor de îmbunătățire de la persoana responsabilă, testarea funcționalităților și flow-urilor create.',
    image: illustration6
  },
  {
    icon: faPaperPlane,
    iconText: 'DEPLOY 💨',
    color: 'info',
    title: 'Implementări de producție',
    description:
      '',
    image: illustration3,
    inverse: true
  }
];

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002/api';

export async function sendRecaptchaToken(data, csrfToken) {
  const config = {
    method: 'post',
    url: `${API_URL}/recaptcha`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function updateLead(data, csrfToken) {
  const config = {
    method: 'put',
    url: `${API_URL}/leads`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function sendLead(data, csrfToken) {
  const config = {
    method: 'post',
    url: `${API_URL}/leads`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}
import { createService, saveService } from "api/services";
import PNotify from "components/common/PNotify/PNotify";
import { getCsrfToken } from "helpers/utils";
import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ServicesModal = ({ modalData }) => {
    const { service, statuses } = modalData.data;
    const [validated, setValidated] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = async data => {
        const csrfToken = await getCsrfToken();
        if (service) {
            data.id = service.id;
        }
        data.status = statuses.find((status) => status.id === data.status);
        const result = service ? await saveService(data, csrfToken) : await createService(data, csrfToken);
        let toastMessage = '';
        if (result && !result.data.error) {
            modalData.onUpdateData(result.data.message);
            toastMessage = toast.success(
                <PNotify
                    title={"Success"}
                    icon="fas fa-check"
                    text={'Service saved with success!'}
                />,
                {
                    containerId: "default",
                    position: toast.POSITION.BOTTOM_RIGHT
                }
            )
        } else {
            toastMessage = toast.error(
                <PNotify
                    title="Error"
                    icon="fas fa-check"
                    text={result.data.message}
                />,
                {
                    containerId: "default",
                    position: toast.POSITION.BOTTOM_RIGHT
                }
            );
        }
        modalData.onHide();
        return toastMessage;
    }

    return (
        <Modal
            show={modalData.isOpen}
            onHide={() => modalData.onHide()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} id="leadForm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-1 g-2">
                        <Form.Group as={Col} controlId="name">
                            <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    placeholder="Name"
                                    name='name'
                                    isInvalid={!!errors.name}
                                    defaultValue={service?.name ?? ''}
                                    {...register('name', {
                                        required: "This field is required!",
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name && errors.name.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group as={Col} controlId="status">
                            <FloatingLabel controlId="floatingInput" label="Status" className="mb-3">
                                <Form.Control
                                    as='select'
                                    className="mb-3"
                                    type="text"
                                    placeholder="Status"
                                    name='status'
                                    isInvalid={!!errors.status}
                                    defaultValue={service?.status?.id ?? null}
                                    {...register('status', {
                                        required: "Please choose a status!",
                                        validate: value =>
                                            value != 0 || "Please choose a status!",
                                    })}
                                >
                                    <option value={0}>Select service status...</option>
                                    {statuses.filter(status => status.tableName === 'services')
                                        .map((status) => {
                                            return <option key={status.id} value={status.id}>{status.name}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.status && errors.status.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1 g-1">
                        <Form.Group as={Col} controlId="description">
                            <FloatingLabel controlId="description" label="Descriere" className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    style={{ height: '100px' }}
                                    placeholder="Descriere"
                                    isInvalid={!!errors.description}
                                    defaultValue={service?.description ?? ''}
                                    {...register('description', {
                                        required: "This field is required!",
                                    })}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.description && errors.description.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => modalData.onHide()}>Close</Button>
                    <Button variant="success" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ServicesModal;
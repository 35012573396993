import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { check, login, storageCsrf } from 'api/auth';
import { getCsrfToken } from 'helpers/utils';

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const navigate = useNavigate();

  useEffect(() => {
    async function checkJWT() {
      const result = await check();
      if (result.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        localStorage.setItem('isLoggedIn', 'false');
      }
    }
    if (localStorage.getItem('isLoggedIn') === 'true') {
      checkJWT();
    }
  }, [navigate]);

  // Handler
  async function handleSubmit(e) {
    e.preventDefault();
    const { email, password, remember } = e.target;
    const csrfToken = await getCsrfToken();
    const data = {
      email: email.value,
      password: password.value,
      remember: remember.checked,
      _csrf: csrfToken
    };
    try {
      const result = await login(data);
      if (result && result.status === 200) {
        const checkResult = await check(data);
        if (checkResult && checkResult.status === 200) {
          localStorage.setItem('isLoggedIn', 'true');
          navigate(`${process.env.PUBLIC_URL}/dashboard`);
          navigate(0);
        } else {
          toast.error(result.data.message, {
            theme: 'colored'
          });
        }
      } else {
        toast.error(result.data.message, {
          theme: 'colored'
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Parolă</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Parolă' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0">Ține-mă minte</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Autentificare
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;

import { Button, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getServices } from "api/services";
import { useContext, useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { getCsrfToken } from "helpers/utils";
import { sendLead } from "api/contactForm";
import { UserContext } from "context/Context";

const AddLeadMeeting = (props) => {
  const {
    scheduleStartDate,
    handleClose,
  } = props;
  const [isBusiness, setIsBusiness] = useState(false);
  const [date, setDate] = useState(scheduleStartDate);
  const [services, setServices] = useState(null)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    projectName: "",
    email: "",
    meetingDate: date,
    description: "",
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    companyIndustry: "",
    services: [],
  });
  const user = useContext(UserContext);

  useEffect(() => {
    const getAllServices = async () => {
      const res = await getServices();
      if (res.status == 200) {
        let vars = res.data.message.map((option) => {
          return { value: option.id, label: option.name };
        });
        setServices(vars);
      }
    }

    if (!services) {
      getAllServices();
    }
  }, [services])

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormData({ ...formData, meetingDate: scheduleStartDate });
    const body = {
      ...formData,
      meetingDate: new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString(),
      services: formData.services.map(service => service.value).join(','),
      responsibleId: user.id,
      fromCalendar: true,
    }
    const csrfToken = await getCsrfToken();
    await sendLead(body, csrfToken);
    handleClose();
  };

  return (
    // TODO CHANGE TO useForm HOOK FOR VALIDATION
    <Form onSubmit={handleFormSubmit}>
      <Modal.Body className="p-card">
        <Row>
          <Form.Group className="mb-3 col-md-6" controlId="titleInput">
            <Form.Label className="fs-0">First name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              required
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-md-6" controlId="titleInput">
            <Form.Label className="fs-0">Last name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              required
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 col-md-6" controlId="titleInput">
            <Form.Label className="fs-0">Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              required
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-md-6" controlId="startDate">
            <Form.Label className="fs-0">Start Date</Form.Label>
            <DatePicker
              selected={date}
              onChange={date => {
                setDate(date);
              }}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="MM-dd-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 col-md-6" controlId="titleInput">
            <Form.Label className="fs-0">Project name</Form.Label>
            <Form.Control
              type="text"
              name="projectName"
              required
              onChange={(e) => setFormData({ ...formData, projectName: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row className='g-2'>
          <Form.Group>
            <Form.Check
              inline
              name='personType'
              type='radio'
              id='bc'
              label='Persoană Fizică'
              defaultChecked
              onClick={() => setIsBusiness(false)}
            />
            <Form.Check
              inline
              name='personType'
              type='radio'
              id='bb'
              label='Persoană Juridică'
              onClick={() => setIsBusiness(true)}
            />
          </Form.Group>
        </Row>
        {
          isBusiness && (
            <>
              <hr />
              <Row className="mb-1 g-2">
                <Form.Group controlId="companyName">
                  <FloatingLabel controlId="floatingInput" label="Nume" className="mb-3">
                    <Form.Control
                      type="text"
                      name="companyName"
                      placeholder="Nume"
                      onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group controlId="companyEmail">
                  <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
                    <Form.Control
                      type="text"
                      name="companyEmail"
                      placeholder="Email"
                      onChange={(e) => setFormData({ ...formData, companyEmail: e.target.value })}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className='mb-1 g-1'>
                <Form.Group controlId="companyPhone">
                  <FloatingLabel controlId="floatingInput" label="&#9742; Telefon" className="mb-3 show-placeholder">
                    <MaskedInput
                      mask={[
                        '+', '4', /\d/, ' ', /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/
                      ]}
                      className='form-control'
                      guide={true}
                      placeholder="+40 XXX XXX XXX"
                      id="companyPhone"
                      value={formData.companyPhone}
                      onChange={(e) => setFormData({ ...formData, companyPhone: e.target.value })}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className='g-2'>
                <Form.Group controlId="companyIndustry">
                  <FloatingLabel controlId="floatingTextArea" label="Industrie" className="mb-3">
                    <Form.Control
                      as="textarea"
                      name="companyIndustry"
                      style={{ height: '100px' }}
                      placeholder="Descriere"
                      onChange={(e) => setFormData({ ...formData, companyIndustry: e.target.value })}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <hr />
            </>
          )
        }

        <Form.Group className="mb-3" controlId="description">
          <Form.Label className="fs-0">Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </Form.Group>
        <Row className='mb-3 g-2'>
          <Form.Group controlId='services'>
            <Form.Label className="fs-0">Services</Form.Label>
            <Select
              closeMenuOnSelect={false}
              options={services}
              placeholder='Select...'
              isMulti
              classNamePrefix="react-select"
              value={formData.services}
              onChange={(selectedOptions) => {
                setFormData({ ...formData, services: selectedOptions });
              }}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-light px-card border-top-0">
        <Button
          variant="primary"
          type="submit"
          onClick={handleClose}
          className="px-4 mx-0"
        >
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
}

AddLeadMeeting.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddLeadMeeting;
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from '../FalconCloseButton';


const ConfirmationModal = ({ title, message, show, onClose }) => {

    return (
            <Modal show={show} onHide={() => onClose(false)} keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <FalconCloseButton onClick={() => onClose(false)} />
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onClose(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => onClose(true)}>Yes</Button>
                </Modal.Footer>
            </Modal>
    )
}

export default ConfirmationModal;
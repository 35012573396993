import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Partners from './Partners';
import Processes from './Processes';
import Services from './Services';
import FooterStandard from './FooterStandard';
import ContactForm from './ContactForm';
import Maintenance from '../maintenance/Maintenance';

//ZZ
const Landing = ({isMaintenance}) => {
  return (
    isMaintenance == true ? (
      <Maintenance />
    ) : isMaintenance == false && (
      <>
        <NavbarStandard />
        <Hero />
        <Partners />
        <Services />
        <Processes />
        <ContactForm />
        {/* <GoogleReview /> */}
        {/* <Testimonial /> */}
        {/* <PricingDefault /> */}
        {/* <Cta /> */}
        <FooterStandard />
      </>
    )
  );
};

export default Landing;

import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IconButton from './IconButton';

const BreadCrumb = ({ current, paths = [], action = null }) => {
    return (
        <div className="pb-3 pt-3">
            {action && (
                <h2 className='font-weight-bold text-6 text-nowrap'>
                    <div className="d-flex flex-col gap-2 me-1 mb-1 align-items-center">
                        <span>{current}</span>
                        <OverlayTrigger
                            placement={'right'}
                            overlay={
                                <Tooltip style={{ position: 'fixed' }} id={'tooltip-right'}>
                                    Add {current}
                                </Tooltip>
                            }
                        >
                            <span>
                                <IconButton
                                    className="rounded-pill justify-content-center align-center"
                                    variant='falcon-primary'
                                    icon='plus'
                                    onClick={action}
                                    transform="shrink-3"
                                />
                            </span>
                        </OverlayTrigger>
                    </div>
                </h2>
            ) || (<h2 className='font-weight-bold text-6 text-nowrap'>{current}</h2>)}
            {paths && (
                <Breadcrumb>
                    {paths.map((path, index) => (
                        <Breadcrumb.Item key={index} href={path.url}>{path.name}</Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            )}
        </div>
    );
}

BreadCrumb.propTypes = {
    current: PropTypes.string.isRequired,
    paths: PropTypes.array,
    action: PropTypes.func.isRequired
};

export default BreadCrumb;

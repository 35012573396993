import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayoutCustom from 'layouts/AuthCardLayoutCustom';

const Login = () => {
  return (
    <AuthCardLayoutCustom
      // leftSideContent={
      //   <p className="text-white">
      //     Don't have an account?
      //     <br />
      //     <Link
      //       className="text-white text-decoration-underline"
      //       to="/authentication/card/register"
      //     >
      //       Get started!
      //     </Link>
      //   </p>
      // }
    >
      <h3>Autentificare</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayoutCustom>
  );
};

export default Login;

import {
  faBalanceScale,
  faGrinStars,
  faHandshake,
  faHardHat,
  faLightbulb,
  faPalette,
  faPeopleCarry,
  faShieldVirus,
  faTools
} from '@fortawesome/free-solid-svg-icons';

export default [
  {
    media: { icon: faTools, color: 'info', className: 'fs-4' },
    title: 'Crearea de Website-uri și Magazine Online',
    description:
      'Crearea și dezvoltarea unui website bazat pe cerințele voastre, optimizat pentru mobil și cu o navigare cât mai rapidă pe toate dispozitivele. Rapoarte pe durata colaborării.'
  },
  {
    media: { icon: faLightbulb, color: 'info', className: 'fs-4' },
    title: 'Idei și Soluții de Actualitate',
    description:
      'Pornind de la scopul de a identifica problemele actuale în afacerea ta, vom găsi o soluție bine pusă la punct care sa fie sustenabilă și de actualitate pentru o perioadă îndelungată.'
  },
  {
    media: { icon: faHandshake, color: 'info', className: 'fs-4' },
    title: 'Consultanță Gratuită',
    description:
      'Oferim expertize de domeniu pentru orice problemă întâmpinată în afacerea ta, pentru oricare dintre variantele de website pe care le aveți sau doriți să le implementați.'
  },
  {
    media: { icon: faBalanceScale, color: 'info', className: 'fs-4' },
    title: 'Optimizare SEO',
    description:
      'Oferim un Audit SEO Gratuit în cazul în care aveți un website deja creat, vă putem ajuta cu elementele importante în SEO și cu factorii de ranking pentru o poziționare optimă.'
  },
  {
    media: { icon: faHardHat, color: 'info', className: 'fs-4' },
    title: 'Mentenanță Website',
    description:
      'În funcție de nevoile website-ului tău ne vom ocupa de administrarea totală a acestuia, prin modificări sau orice altă situație apărută în timp pentru ca tu să te bucuri de experiența site-ului tău.'
  },
  {
    media: { icon: faShieldVirus, color: 'info', className: 'fs-4' },
    title: 'Securitate',
    description:
      'Rezolvarea și implementarea problemelor de securitate a website-ului tău, împreună cu protejarea atacurilor cibernetice și implementarea protocoalelor de securitate a bazei de date în conformitate cu GDPR.'
  },
  {
    media: { icon: faPalette, color: 'info', className: 'fs-4' },
    title: 'Design Grafic și Web Design',
    description:
      'Oferim personalizarea și stilizarea website-ului tău, împreună cu crearea unui logo și tot ce implică grafica unui website bazat pe cerințele voastre.'
  },
  {
    media: { icon: faGrinStars, color: 'info', className: 'fs-4' },
    title: 'Servicii de Calitate',
    description:
      'Calitatea serviciilor noastre este bazată pe profesionalismul nostru, implementarea unui proiect se va finaliza într-un timp cât mai scurt posibil și înclinația noastra va fi către satisfacerea clientului.'
  },
  {
    media: { icon: faPeopleCarry, color: 'info', className: 'fs-4' },
    title: 'Alte Servicii',
    description:
      'Vă oferim acces la colaboratorii noștrii prin care vă putem oferii următoarele: Contabilitate, Marketing, Consultanță Juridică, Licențe Software, Consultanță pentru Proiecte Guvernamentale, etc.'
  }
];

import { updateLead } from "api/contactForm";
import { getCsrfToken } from "helpers/utils";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import classNames from 'classnames';
import PNotify from "components/common/PNotify/PNotify";
import { toast } from "react-toastify";

const LeadsModal = ({ lead, statuses, responsibleUsers, show, onHide, onUpdateLead }) => {
    const [currentLead, setCurrentLead] = useState(lead);
    const [validated, setValidated] = useState(false);
    const [datePickerError, setDatePickerError] = useState(false);

    const isWeekday = (datePicker) => {
        const day = datePicker.getDay();
        return day !== 0 && day !== 6;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        let toastData = '';
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            const csrfToken = await getCsrfToken();
            const result = await updateLead(currentLead, csrfToken);
            if (result && !result.data.error) {
                onUpdateLead(result.data.message);
                toastData = toast.success(
                    <PNotify
                        title="Success"
                        icon="fas fa-check"
                        text={'Lead updated with success!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
            } else {
                toastData = toast.error(
                    <PNotify
                        title="Error"
                        icon="fas fa-check"
                        text={result.data.message}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
            }
            onHide(false);
            return toastData;
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => onHide(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={onSubmit} id="leadForm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-1 g-2">
                        <Form.Group as={Col} controlId="meetingLink">
                            <FloatingLabel controlId="floatingInput" label="Meeting Link" className="mb-3">
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    placeholder="Meeting Link"
                                    name='meetingLink'
                                    defaultValue={currentLead.meetingLink ?? ''}
                                    onChange={(e) => { setCurrentLead({ ...currentLead, meetingLink: e.target.value }) }}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid link.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="status">
                            <FloatingLabel controlId="floatingInput" label="Status" className="mb-3">
                                <Form.Select
                                    className="mb-3"
                                    type="text"
                                    placeholder="Status"
                                    name='status'
                                    defaultValue={currentLead.status.id ?? ''}
                                    onChange={(e) => { setCurrentLead({ ...currentLead, status: statuses.find((status) => status.id === e.target.value) }) }}
                                >
                                    <option>Select lead status...</option>
                                    {statuses.filter(status => status.tableName === 'leads')
                                        .map((status) => {
                                            return <option key={status.id} value={status.id}>{status.name}</option>
                                        })
                                    }
                                </Form.Select>
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid status.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="mb-3 g-1" controlId="datePicker">
                        <ReactDatePicker
                            selected={new Date(currentLead.meetingDate)}
                            locale="ro"
                            name="datePicker"
                            onChange={(datePicker) => { setCurrentLead({ ...currentLead, meetingDate: datePicker }) }}
                            formatWeekDay={day => day.slice(0, 3)}
                            className={classNames('form-control', {
                                'is-invalid': datePickerError,
                            })}
                            placeholderText="Programeză o întâlnire"
                            timeIntervals={30}
                            filterDate={isWeekday}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            showTimeSelect
                            fixedHeight
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="Responsible">
                        <FloatingLabel controlId="floatingInput" label="Responsible" className="mb-3">
                            <Form.Select
                                className="mb-3"
                                type="text"
                                placeholder="Responsible"
                                name='responsible'
                                defaultValue={currentLead?.responsible?.id ?? ''}
                                onChange={(e) => { setCurrentLead({ ...currentLead, responsible: responsibleUsers.find((user) => user.id === e.target.value) }) }}
                            >
                                <option>Select responsible...</option>
                                {responsibleUsers && responsibleUsers.map((user) => {
                                        return <option key={user.id} value={user.id}>{user.lastName && user.firstName ? `${user.lastName} ${user.firstName}` : user.email}</option>
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid status.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onHide(false)}>Close</Button>
                    <Button variant="success" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LeadsModal;

import { useEffect } from 'react';
import { logout, storageCsrf } from 'api/auth';
import { useNavigate } from 'react-router-dom';
import { getCsrfToken } from 'helpers/utils';

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    async function logoutJWT() {
      const csrfToken = await getCsrfToken();
      const result = await logout(csrfToken);
      if (result?.status === 200) {
        localStorage.setItem('isLoggedIn', 'false');
      }
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
    logoutJWT();
    document.querySelector('body').classList.add('loaded');
  }, [navigate]);

  return null;
}

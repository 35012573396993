import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002/api';

export async function getServices(csrfToken) {
  const config = {
    method: 'get',
    url: `${API_URL}/services`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function createService(data, csrfToken) {
  const config = {
    method: 'post',
    url: `${API_URL}/services`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function saveService(data, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/services`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function deleteService(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/services/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}
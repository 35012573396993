import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002/api';

export async function deleteLead(csrfToken, id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/leads/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function changeMeetingStatus(csrfToken, data) {
  const config = {
    method: 'post',
    url: `${API_URL}/leads/meeting`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    },
    data
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    })
}

export async function getLeads(csrfToken) {
  const config = {
    method: 'get',
    url: `${API_URL}/leads`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function getConfirmedLeads(csrfToken) {
  const config = {
    method: 'get',
    url: `${API_URL}/leads/confirmedLeads`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function getFile(csrfToken, id, clientId) {
  const config = {
    method: 'get',
    url: `${API_URL}/leads/file/${clientId}/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    }
  };
  return await axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
}

export async function getFiles(csrfToken, leadId) {
  const config = {
    method: 'get',
    url: `${API_URL}/leads/files/${leadId}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken
    },
    responseType: 'blob'
  };
  return await axios(config)
    .then(response => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${leadId}_files.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      return error.response;
    });
}

import React from 'react';
import Spinner from 'react-bootstrap/Spinner'; // Import the Spinner component from your library or package

const Loading = () => {
  return (
    <div className='d-flex justify-content-center align-items-center min-vh-100 flex-column gap-2'>
      <p className='pl-5'>Loading...</p>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loading;
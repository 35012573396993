import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002/api';

export async function getResponsibleUsers(csrfToken) {
    const config = {
      method: 'get',
      url: `${API_URL}/account/responsible-users`,
      withCredentials: true,
      headers: {
        'csrf-token': csrfToken
      }
    };
    return await axios(config)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export const bgWhiteIcons = [
  { icon: ['fab', 'facebook-f'], color: 'facebook', bg: 'white', href: 'https://www.facebook.com/kerrsid', target: '_blank' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', bg: 'white', href: 'https://www.linkedin.com/company/kerrsid/about/', target: '_blank' },
  { icon: faInstagram, color: 'instagram', bg: 'white', href: 'https://instagram.com/kerrsid_net?igshid=MzRlODBiNWFlZA==', target: '_blank' },
];
export default [
  { icon: ['fab', 'facebook-f'], color: 'facebook', href: '#!' },
  { icon: ['fab', 'twitter'], color: 'twitter', href: '#!' },
  {
    icon: ['fab', 'google-plus-g'],
    color: 'google-plus',
    href: '#!'
  },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', href: '#!' },
  { icon: ['fab', 'medium-m'], color: '700', href: '#!' }
];

import React, { useContext, useEffect, useState } from 'react';
import { Nav, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { getMaintenanceStatus, changeMaintenanceStatus } from 'api/settings';
import { storageCsrf } from 'api/auth';
import { getCsrfToken } from 'helpers/utils';

const TopNavRightSideNavItem = () => {

const [isMaintenance, setIsMaintenance] = useState(null);
const [isOnline, setIsOnline] = useState('');

async function getMaintenance() {
  const result = await getMaintenanceStatus();
  if (result.status === 200) {
    setIsMaintenance(result.data);
  }
}

useEffect(() => {
  if (isMaintenance == null) {
    getMaintenance();
    isMaintenance == true ? setIsOnline("Online"): setIsOnline("Offline");
  }
}, [isMaintenance, isOnline]);
  
const toggleMaintenance = async () => {
  const csrfToken = await getCsrfToken();
  setIsMaintenance(!isMaintenance);
  const object = {
      isMaintenance: !isMaintenance
  }
  isMaintenance == true ? setIsOnline("Online"): setIsOnline("Offline");
  changeMaintenanceStatus(object ,csrfToken);
}
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Comuta tema light' : 'Comuta tema dark'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'} className="d-flex flex-column justify-content-center align-items-center">
          {isOnline}
          <Form.Check
          type="switch"
          id="custom-switch"
          onChange={toggleMaintenance}
          checked={isMaintenance} 
        /> 
      </Nav.Item>

      {/* <CartNotification /> */}
      {/* <NotificationDropdown /> */}
      {/* <NineDotMenu /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;

import "./Maintenance.scss";
import logo from 'assets/img/logos/kerrsid_logo.png';
import maintenance from 'assets/img/gallery/maintenance4.png';

const Maintenance = () => {
    return (
      <>
        <div className="maintenance-container">
            <img  className="maintenance-img" src={maintenance} alt="under construction"></img>
            <div className="d-flex flex-column align-items-center kerrsid-message">
                <img className="logo-image" src={logo} alt="Logo" />
                <h1>We've got something special for you.</h1>
                <h3>And we can't wait for you to see it.</h3>
                <h3>Please check back soon.</h3>
            </div>
        </div>
      </>
    );
  };

export default Maintenance;
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { getCsrfToken } from 'helpers/utils';
import { deleteLead } from 'api/leads';
import { toast } from 'react-toastify';
import PNotify from 'components/common/PNotify/PNotify';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 text-center me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => {

  const renderNestedContent = (item) => {
    if (typeof item === 'object') {
      return Object.keys(item).map((key) => {
        if (typeof item[key] === 'object') {
          return renderNestedContent(item[key]);
        } else {
          if (item[key].startsWith('http')) {
            const url = new URL(content);
            const domainText = url.hostname
              .replace(/^www\./, '')
              .charAt(0)
              .toUpperCase() + url.hostname.slice(1).replace(/\..*$/, '');
            return <a href={url} target="_blank" rel="noopener noreferrer">{domainText}</a>;
          } else {
            return <p className="mb-0 text-justify">{item[key]}</p>;
          }
        }
      });
    } else {
      if (item.startsWith('http')) {
        const url = new URL(content);
        const domainText = url.hostname
          .replace(/^www\./, '')
          .charAt(0)
          .toUpperCase() + url.hostname.slice(1).replace(/\..*$/, '');
        return <a href={url} target="_blank" rel="noopener noreferrer">{domainText}</a>;
      } else {
        return <p className="mb-0 text-justify">{item}</p>;
      }
    }
  };


  return (
    <Flex className="mt-3">
      {getCircleStackIcon(icon)}
      <div className="flex-1">
        <h6>{heading}</h6>
        {children || renderNestedContent(content)}
      </div>
    </Flex>
  );
};

const CalendarEventModal = ({
  setIsOpenEventModal,
  isOpenEventModal,
  modalEventContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };
  const { title } = isOpenEventModal && modalEventContent.event;
  const { responsible, meetingLink, services, profile, modalHeaderClassName, leadId } =
    isOpenEventModal && modalEventContent.event.extendedProps;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const confirmDelete = async (result) => {
    if (result === true) {
      const csrfToken = await getCsrfToken();
      const deleteResult = await deleteLead(csrfToken, leadId);
      if (deleteResult && deleteResult.data.error !== 1) {
        window.location.reload(false);
      } else {
        return toast.error(
          <PNotify
            title="Error"
            icon="fas fa-check"
            text={deleteResult.data.message}
          />,
          {
            containerId: "default",
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      }

    }
    setIsOpenConfirmationModal(!isOpenConfirmationModal);
  };
  const handleDelete = () => {
    setIsOpenConfirmationModal(!isOpenConfirmationModal);
  }
  return (
    <Modal
      show={isOpenEventModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className={`bg-light px-card border-bottom-0 ${modalHeaderClassName}`}
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-card pb-card pt-1 fs--1">
        <EventModalMediaContent
          icon="align-left"
          heading="Lead responsible"
        >
          {responsible ? (
            responsible.email) : (
            <p>-</p>
          )}
        </EventModalMediaContent>
        <EventModalMediaContent icon="envelope" heading="Contact info">
          {profile ? (
            profile.email
          ) : (
            <p>-</p>
          )}
        </EventModalMediaContent>
        {services && (
          <EventModalMediaContent icon="clock" heading="Services">
            <ul className="list-unstyled timeline mb-0">
              {services.map((service, index) => (
                <li key={index}>{service.name}</li>
              ))}
            </ul>
          </EventModalMediaContent>
        )}
        {meetingLink && (
          <EventModalMediaContent
            icon="link"
            heading="Meeting Link"
            content={meetingLink ?? '-'}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="bg-light px-card border-top-0">
        <Button
          onClick={() => handleDelete()}
          variant="falcon-default"
          size="sm"
        >
          <FontAwesomeIcon icon="trash-alt" className="fs--2 me-2" />
          <span>Delete</span>
        </Button>
        <Button
          as={Link}
          to={`/dashboard/leads?id=${leadId}`}
          variant="falcon-primary"
          size="sm"
        >
          <span>See more details</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
        </Button>
      </Modal.Footer>
      {isOpenConfirmationModal && (
        <>
          <ConfirmationModal
            show={isOpenConfirmationModal}
            title="Delete lead"
            message="Are you sure you want to delete this lead?"
            onClose={(result) => confirmDelete(result)}
          />
        </>
      )}
    </Modal>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarEventModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  modalEventContent: PropTypes.object.isRequired
};

export default CalendarEventModal;

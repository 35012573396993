import bw2 from 'assets/img/logos/b&w/3.png';
import bw3 from 'assets/img/logos/b&w/2.png';
import bw4 from 'assets/img/logos/b&w/4.png';
import bw5 from 'assets/img/logos/b&w/1.png';
import bw7 from 'assets/img/logos/b&w/12.png';

export default [
  { src: bw7, height: 60 },
  { src: bw3, height: 60 },
  { src: bw5, height: 30 },
  { src: bw2, height: 50 },
  { src: bw4, height: 60 }
];

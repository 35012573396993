import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteService, getServices } from "api/services";
import ActionButton from "components/common/ActionButton";
import BreadCrumb from "components/common/BreadCrumb";
import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooterKerrsid from "components/common/advance-table/AdvanceTableFooterKerrsid";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import TableActionButtons from "components/common/advance-table/TableActionButtons";
import { getCsrfToken, getStatusStyling } from "helpers/utils";
import { useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import ServicesModal from "./ServicesModal";
import { getStatuses } from "api/statuses";
import ConfirmationModal from "components/common/Modals/ConfirmationModal";
import { toast } from "react-toastify";
import PNotify from "components/common/PNotify/PNotify";


const Services = () => {
    const [services, setServices] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [modal, setModal] = useState({ isOpen: false, data: null, onHide: null, onUpdateData: null, type: null });

    const columns = [
        {
            accessor: 'name',
            Header: 'Name',
        },
        {
            accessor: 'description',
            Header: 'Description',
            Cell: ({ cell: { value } }) => {
                const text = value.length > 30 ? value.substring(0, 30) + '...' : value;
                return (
                    <>
                        <Accordion flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <span style={{ fontSize: '80%' }}>{text}</span>
                                </Accordion.Header>
                                <Accordion.Body style={{ overflow: 'hidden', whiteSpace: 'normal' }}>{value}</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                )
            },
        },
        {
            accessor: (props) => ([props?.status?.name]),
            Header: 'Status',
            Cell: rowData => {
                const { status } = rowData.row.original
                if (status) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SoftBadge pill bg={getStatusStyling(status).color}>
                                {status.name}
                                <FontAwesomeIcon icon={getStatusStyling(status).icon} className="ms-2" />
                            </SoftBadge>
                        </div>
                    )
                }
            }
        },
        {
            Header: 'Actions',
            accessor: 'id',
            className: 'actions',
            headerClassName: 'justify-content-center',
            width: 100,
            Cell: (rowData) => (
                <>
                    <Flex justifyContent={'center'} alignContent={'center'} className={'gap-2'}>
                        <ActionButton icon="edit" title="Edit" variant="action" className="btn-xs btn-falcon-primary" onClick={() => handleEdit(rowData.row.original.id)} />
                        <ActionButton icon="trash" title="Delete" variant="action" color="red" className="btn-xs btn-falcon-primary" onClick={() => handleDelete(rowData.row.original.id)} />
                    </Flex>
                </>
            ),
        },
    ];

    useEffect(() => {
        async function getServicesData() {
            const csrfToken = await getCsrfToken();
            const result = await getServices(csrfToken);
            if (result) {
                setServices(result.data.message);
            }
        }

        async function getStatusesData() {
            const csrfToken = await getCsrfToken();
            const result = await getStatuses(csrfToken);
            if (result) {
                setStatuses(result.data.message);
            }
        }

        if (!services.length) {
            getServicesData();
        }

        if (!statuses.length) {
            getStatusesData();
        }
    }, [statuses, services])

    const onUpdateService = (updatedService) => {
        const updatedServices = services.map((service) => {
            return service.id === updatedService.id ? updatedService : service;
        })
        setServices(updatedServices);
        setModal({ ...modal, isOpen: false });
    }

    const handleCreate = (id) => {
        setModal({
            ...modal,
            isOpen: true,
            type: 'addOrEdit',
            onHide: () => setModal({ ...modal, isOpen: false }),
            onUpdateData: (service) => { setServices((prevServices) => [...prevServices, service]) },
            data: {
                service: null,
                statuses: statuses
            }
        })
    }

    const handleEdit = (id) => {
        const editedService = services.find((service) => service.id === id);
        setModal({
            ...modal,
            isOpen: true,
            type: 'addOrEdit',
            onHide: () => setModal({ ...modal, isOpen: false }),
            onUpdateData: (item) => { onUpdateService(item) },
            data: {
                service: editedService,
                statuses: statuses,
            }
        });
    }

    const handleDelete = (id) => {
        const deletedService = services.find((service) => service.id === id);
        setModal({
            ...modal,
            type: 'delete',
            title: `Delete service ${deletedService.name}`,
            message: 'Are you sure you want to delete this service?',
            isOpen: true,
            onHide: () => setModal({ ...modal, isOpen: false }),
            onUpdateData: (item) => { onUpdateService(item) },
            data: {
                service: deletedService,
            }
        });
    }

    const handleDeleteConfirmation = async (result) => {
        let toastMessage = '';
        if (result === true) {
            const csrfToken = await getCsrfToken();
            const deleteResult = await deleteService(modal.data.service.id, csrfToken);
            if (deleteResult && !deleteResult.data.error) {
                toastMessage = toast.success(
                    <PNotify
                        title="Success"
                        icon="fas fa-check"
                        text={'Service deleted with success!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
                const updatedServices = services.filter((service) => service.id !== modal.data.service.id);
                setServices(updatedServices);
            } else {
                toastMessage = toast.error(
                    <PNotify
                        title="Error"
                        icon="fas fa-check"
                        text={deleteResult?.data?.message ?? 'Error! Service could not be deleted!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
            }
        }
        setModal({ ...modal, isOpen: false });
        return toastMessage;
    }

    return (
        <>
            <BreadCrumb
                current={'Services'}
                action={() => handleCreate()}
            />
            {/* Table */}
            <Card>
                <Card.Body>
                    <AdvanceTableWrapper
                        columns={columns ?? []}
                        data={services || []}
                        sortable
                        pagination
                        perPage={5}
                    >
                        <Row className="flex-end-center mb-3">
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                        <TableActionButtons exportBtn={true} handleExportClick={''} />
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                        {services.length ? (
                            <div className="mt-3">
                                <AdvanceTableFooterKerrsid
                                    rowCount={services.length ?? 0}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </div>
                        ) : ''}
                    </AdvanceTableWrapper>
                </Card.Body>
            </Card>
            {/* Table */}

            {/* Modals */}
            {modal.isOpen && modal.type == 'addOrEdit' && modal.data && (
                <ServicesModal
                    modalData={modal}
                />
            )}
            {modal.isOpen && modal.type == 'delete' && modal.data && (
                <ConfirmationModal
                    show={modal.isOpen}
                    title={modal.title}
                    message={modal.message}
                    onClose={handleDeleteConfirmation}
                />
            )}
            {/* Modals */}

        </>
    );
}

export default Services;
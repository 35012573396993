import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import AddLeadMeeting from './AddLeadMeeting';
import AddClientMeeting from './AddClientMeeting';

const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  setInitialEvents,
  initialEvents,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({});
  const [scheduleType, setScheduleType] = useState('Lead');

  const handleClose = () => {
    // TODO REFACTOR THIS TO ADD NEW LEAD EVENT WITHOUT REFRESH
    setIsOpenScheduleModal(false);
    window.location.reload();
  };

  const handleScheduleTypeChange = ({ target }) => {
    let name = target.value;
    setScheduleType(target.selectedOptions[0].text)
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  }

  const handleChange = ({ target }) => {
    let name = target.value;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData({
        ...formData,
        start: scheduleStartDate,
        end: scheduleEndDate
      });
    } else {
      setScheduleStartDate(null);
      setScheduleEndDate(null);
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate, scheduleType]);

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-card border-bottom-0"
      >
        <Modal.Title as="h5"> Create Schedule</Modal.Title>
      </Modal.Header>
      <Form.Group className="p-card mb-3" controlId="label">
        <Form.Label className="fs-0">Meeting type</Form.Label>
        <Form.Select name="className" onChange={handleScheduleTypeChange}>
          <option value="bg-soft-warning">Lead</option>
          <option value="bg-soft-info">Client</option>
        </Form.Select>
      </Form.Group>
      {scheduleType == 'Lead' && (
        <AddLeadMeeting
          handleChange={handleChange}
          scheduleStartDate={scheduleStartDate}
          initialEvents={initialEvents}
          setInitialEvents={setInitialEvents}
          setScheduleEndDate={setScheduleEndDate}
          setScheduleStartDate={setScheduleStartDate}
          handleClose={handleClose}
        />
      )}
      {scheduleType == 'Client' && (
        <AddClientMeeting />
      )}
    </Modal>
  );
};

AddScheduleModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default AddScheduleModal;

import { Col, Row } from "react-bootstrap";
import IconButton from "../IconButton";


const TableActionButtons = ({
    newBtn,
    handleNewClick,
    exportBtn,
    handleExportClick
}) => {
    return (
        <Row className="flex-between-center mb-3">
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="orders-actions">
                    {newBtn && (
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            className="me-2"
                            onClick={handleNewClick}
                        >
                            <span className="d-none d-sm-inline-block ms-1">New</span>
                        </IconButton>
                    )}
                    {exportBtn && (
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="external-link-alt"
                            transform="shrink-3"
                            onClick={handleExportClick}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Export</span>
                        </IconButton>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default TableActionButtons;
import React, { useContext } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg3 from 'assets/img/generic/bg-3.jpg';
import imageDark from 'assets/img/generic/22.jpg';
import Section from 'components/common/Section';
import AppContext from 'context/Context';

const Hero = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Section
      className="py-0 overflow-hidden light"
      id="acasa"
      image={bg3}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <Button
            variant="outline-danger"
            className="mb-4 fs--1 border-2 rounded-pill"
            onClick={() => {
              var element = document.getElementById("contact");
              element.scrollIntoView({
                behavior:"smooth",
                block: "start"
              })
            }}
          >
            <span className="me-2" role="img" aria-label="phone">
            ☎
            </span>
            Contactează-ne!
          </Button>
          <h1 className="text-white fw-light" style={{minHeight:'7rem', paddingTop: '1rem'}}>
            <Typed
              strings={['Crearea de Magazine Online!', 'Audit SEO Gratuit!', 'Idei și Soluții de Actualitate!', 'Consultanță Gratuită!', 'Optimizare SEO!', 'Mentenanță Website!', 'Securitate!', 'Design Grafic și Web Design!', 'Servicii de Calitate!', 'Alte Servicii!']}
              typeSpeed={40}
              backSpeed={50}
              className="fw-bold"
              // style={{minHeight:'6rem'}}
              loop
            />
          </h1>
          <p className="lead text-white opacity-75">
            Vrei să concurezi cu liderii de top? Oferim soluții web de calitate, special pentru afacerea ta.
          </p>
          {/* <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="#!"
          >
            Ești la un click distanță!
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button> */}
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-start mt-4 mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={imageDark}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;

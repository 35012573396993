const statusDictionary = {
    success: {
        color: 'success',
        icon: 'check',
    },
    error: {
        color: 'danger',
        icon: 'times',
    },
    client: {
        color: 'primary',
        icon: 'user',
    },
    admin: {
        color: 'success',
        icon: 'user',
    },
    lead: {
        color: 'primary',
        icon: 'user',
    },
    pending: {
        color: 'danger',
        icon: 'times',
    },
    in_progress: {
        color: 'danger',
        icon: 'times',
    },
    confirmed: {
        color: 'danger',
        icon: 'times',
    },
    cancelled: {
        color: 'danger',
        icon: 'times',
    },
    expired: {
        color: 'danger',
        icon: 'times',
    },
    accepted: {
        color: 'danger',
        icon: 'times',
    },
    refused: {
        color: 'danger',
        icon: 'times',
    },
    default: {
        color: 'secondary',
        icon: 'times',
    }
};

export default statusDictionary;
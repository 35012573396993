import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const NavbarItemToSection = ({ title, section }) => {

  return (
    <Nav.Link
      as={title ? 'p' : Link}
      className={classNames('fw-medium', {
        'text-700 mb-0 fw-bold': title,
        'py-1': !title,
        'cursor-pointer': title
      })}
      onClick={()=> {
        var element = document.getElementById(section);
        element.scrollIntoView({
          behavior:"smooth",
          block: "start"
        })
      }}
    >
      {title ? title : "route.name"}
    </Nav.Link>
  );
};

NavbarItemToSection.propTypes = {
  title: PropTypes.string,
  section: PropTypes.string
};

export default NavbarItemToSection;

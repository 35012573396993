import classNames from 'classnames';
import React, { Children, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import kerrsidLogo from '../../../assets/img/logos/kerrsid_logo_rr_circle.png';
import NavbarItemToSection from 'components/navbar/top/NavbarItemToSection';
import NavbarNavLink from 'components/navbar/top/NavbarNavLink';
import { pagesRoutes } from 'routes/routes';

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark
      })}
    >
      <Container>
        <Navbar.Brand className="text-white dark__text-white" as={Link} to="/">
          <Image
            width="55"
            fluid
            rounded
            align="center"
            className="me-2 mt-2 mb-2 cursor-pointer"
            src={kerrsidLogo}
            alt="kerrsid-creare-website-logo"
          />
          Kerrsid
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Navbar.Collapse className="scrollbar">
          <Nav>
            {/* <NavbarNavLink route={pagesRoutes.children.find(({ name }) => name === 'Landing')} title="Acasă" /> */}
            <NavbarItemToSection section="acasa" title="Acasă" />
            <NavbarItemToSection section="services" title="Serviciile Noastre" />
            <NavbarItemToSection section="metodologie" title="Metodologie" />
            <NavbarItemToSection section="contact" title="Contact" />
            {/* <NavbarItemToSection section="recenzii" title="Recenzii" /> */}
          </Nav>
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;

/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import { useLocation } from 'react-router-dom';

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Search...'
}) => {
  const [value, setValue] = useState(globalFilter);
  const [urlFilterApplied, setUrlFilterApplied] = useState(false);
  const location = useLocation();

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const paramValue = query.get('id');

    if (paramValue && !urlFilterApplied) {
      setValue(paramValue);
      setGlobalFilter(paramValue);
      setUrlFilterApplied(!urlFilterApplied)
    } else {
      setGlobalFilter(value);
    }
  }, [location.search, globalFilter]);

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
        }}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <InputGroup.Text className="bg-transparent">
        <FontAwesomeIcon icon="search" className="fs--1 text-600" />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
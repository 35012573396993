import React, { useContext, useEffect, useReducer } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import BreadCrumb from 'components/common/BreadCrumb';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LeadsModal from './LeadsModal';
import { deleteLead, getLeads, changeMeetingStatus } from 'api/leads';
import Loading from 'components/utilities/Loading';
import { getCsrfToken, getStatusStyling } from 'helpers/utils';
import InfoModal from 'components/common/Modals/InfoModal';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import ActionButton from 'components/common/ActionButton';
import AdvanceTableFooterKerrsid from 'components/common/advance-table/AdvanceTableFooterKerrsid';
import TableActionButtons from 'components/common/advance-table/TableActionButtons';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PNotify from 'components/common/PNotify/PNotify';
import { toast } from 'react-toastify';
import { getStatuses } from 'api/statuses';
import { getFiles } from 'api/leads';
import { UserContext } from 'context/Context';
import { getResponsibleUsers } from 'api/users';

// Define initial state
const initialState = {
    isOpenModal: false,
    isInfoModalOpen: false,
    isOpenConfirmationModal: false,
    isDescriptionModalOpen: false,
    lead: null,
    loading: true,
    leads: null,
    responsibleUsers: null,
    statuses: null,
    confirmationMessage: '',
    confirmationTitle: '',
    confirmationType: null,
};

// Reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LEADS':
            return { ...state, leads: action.payload };
        case 'SET_RESPONSIBLE_USERS':
            return { ...state, responsibleUsers: action.payload };
        case 'SET_STATUSES':
            return { ...state, statuses: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'OPEN_MODAL':
            return { ...state, isOpenModal: true, lead: action.payload };
        case 'CLOSE_MODAL':
            return { ...state, isOpenModal: false };
        case 'OPEN_INFO_MODAL':
            return { ...state, isInfoModalOpen: true };
        case 'CLOSE_INFO_MODAL':
            return { ...state, isInfoModalOpen: false };
        case 'OPEN_CONFIRMATION_MODAL':
            return {
                ...state,
                isOpenConfirmationModal: true,
                lead: action.payload.lead,
                confirmationMessage: action.payload.confirmationMessage,
                confirmationTitle: action.payload.confirmationTitle,
                confirmationType: action.payload.confirmationType
            };
        case 'CLOSE_CONFIRMATION_MODAL':
            return { ...state, isOpenConfirmationModal: false };
        default:
            throw new Error('Error! Action type does not exist!');
    }
};

const Leads = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const user = useContext(UserContext);

    useEffect(() => {
        async function getLeadsData() {
            const csrfToken = await getCsrfToken();
            const result = await getLeads(csrfToken);
            if (result.status === 200) {
                dispatch({ type: 'SET_LEADS', payload: result.data.message });
                dispatch({ type: 'SET_LOADING', payload: false });
            }
        }

        async function getStatusesData() {
            const csrfToken = await getCsrfToken();
            const result = await getStatuses();
            if (result.status === 200) {
                dispatch({ type: 'SET_STATUSES', payload: result.data.message });
            }
        }

        async function getResponsibleUsersData() {
            const csrfToken = await getCsrfToken();
            const result = await getResponsibleUsers(csrfToken);
            if (result && result.error != 1) {
                dispatch({ type: 'SET_RESPONSIBLE_USERS', payload: result.data.message });
            }
        }

        if (!state.leads) {
            getLeadsData();
        }
        if (!state.statuses) {
            getStatusesData();
        }
        if (!state.responsibleUsers) {
            getResponsibleUsersData();
        }
    }, [state.leads, state.statuses, state.responsibleUsers]);

    const handleDelete = (id) => {
        const leadToDelete = state.leads.find((lead) => lead.id === id);
        const payload = {
            lead: leadToDelete,
            confirmationTitle: `Delete lead ${leadToDelete.profile.lastName} ${leadToDelete.profile.firstName} ?`,
            confirmationMessage: 'Are you sure you want to delete this lead ?',
            confirmationType: 'DELETE'
        }
        dispatch({ type: 'OPEN_CONFIRMATION_MODAL', payload: payload });
    };

    const handleExportClick = () => {
        dispatch({ type: 'OPEN_INFO_MODAL' });
    };

    const handleEdit = (id) => {
        const leadToEdit = state.leads.find((lead) => lead.id === id);
        dispatch({ type: 'OPEN_MODAL', payload: leadToEdit });
    };

    const handleConfirm = (id) => {
        const leadToConfirm = state.leads.find((lead) => lead.id === id);
        const payload = {
            lead: leadToConfirm,
            confirmationTitle: `Confirm meeting with lead ${leadToConfirm.profile.lastName} ${leadToConfirm.profile.firstName} ?`,
            confirmationMessage: 'Are you sure you want to confirm a meeting with this lead ?',
            confirmationType: 'CONFIRM_MEETING'
        }
        dispatch({ type: 'OPEN_CONFIRMATION_MODAL', payload: payload });
    }

    const handleRefuse = (id) => {
        const leadToConfirm = state.leads.find((lead) => lead.id === id);
        const payload = {
            lead: leadToConfirm,
            confirmationTitle: `Refuse meeting with lead ${leadToConfirm.profile.lastName} ${leadToConfirm.profile.firstName} ?`,
            confirmationMessage: 'Are you sure you want to refuse a meeting with this lead ?',
            confirmationType: 'REFUSE_MEETING'
        }
        dispatch({ type: 'OPEN_CONFIRMATION_MODAL', payload: payload });
    }

    const onUpdateLead = (updatedLeadData) => {
        const updatedLeads = state.leads.map((lead) =>
            lead.id === updatedLeadData.id ? updatedLeadData : lead
        );
        dispatch({ type: 'SET_LEADS', payload: updatedLeads });
        dispatch({ type: 'CLOSE_MODAL' });
    };

    const handleFilesDownload = async (leadId) => {
        const csrfToken = await getCsrfToken();
        const result = await getFiles(csrfToken, leadId);
        if(result && result.data.error) {
            return toast.error(
                <PNotify
                    title="Error"
                    icon="fas fa-check"
                    text={result?.data?.message ?? 'Error! No files to download!'}
                />,
                {
                    containerId: "default",
                    position: toast.POSITION.BOTTOM_RIGHT
                }
            );
        }
    }

    const meetingStatus = async (result, type) => {
        let toastMessage = '';
        if (result === true) {
            const csrfToken = await getCsrfToken();
            const data = {
                leadId: state.lead.id,
                type: type,
                responsibleId: user.id
            }
            const result = await changeMeetingStatus(csrfToken, data);
            if (result && !result.data.error) {
                const leadIndex = state.leads.findIndex(lead => result.data.message.id === lead.id);
                if (leadIndex !== -1) {
                    const updatedLeads = [...state.leads];
                    updatedLeads[leadIndex] = result.data.message;

                    dispatch({ type: 'SET_LEADS', payload: updatedLeads });
                }
                toastMessage = toast.success(
                    <PNotify
                        title="Success"
                        icon="fas fa-check"
                        text={'Status updated with success!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
            } else {
                toastMessage = toast.error(
                    <PNotify
                        title="Error"
                        icon="fas fa-check"
                        text={result?.data?.message ?? 'Error! Status could not be updated!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
            }
        }
        dispatch({ type: 'CLOSE_CONFIRMATION_MODAL' });
        return toastMessage;
    }

    const confirmDelete = async (result) => {
        let toastMessage = '';
        if (result === true) {
            const csrfToken = await getCsrfToken();
            const deleteResult = await deleteLead(csrfToken, state.lead.id);
            if (deleteResult && !deleteResult.data.error) {
                toastMessage = toast.success(
                    <PNotify
                        title="Success"
                        icon="fas fa-check"
                        text={'Lead deleted with success!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
                const updatedLeads = state.leads.filter((obj) => obj.id !== state.lead.id);
                dispatch({ type: 'SET_LEADS', payload: updatedLeads });
            } else {
                toastMessage = toast.error(
                    <PNotify
                        title="Error"
                        icon="fas fa-check"
                        text={deleteResult?.data?.message ?? 'Error! Lead could not be deleted!'}
                    />,
                    {
                        containerId: "default",
                        position: toast.POSITION.BOTTOM_RIGHT
                    }
                );
            }
        }
        dispatch({ type: 'CLOSE_CONFIRMATION_MODAL' });
        return toastMessage;
    };

    const handleConfirmation = (type, result) => {
        switch (type) {
            case 'DELETE':
                confirmDelete(result);
                break;
            case 'CONFIRM_MEETING':
                meetingStatus(result, 'confirmed');
                break;
            case 'REFUSE_MEETING':
                meetingStatus(result, 'refused');
            default:
                break;
        }
    }

    if (state.loading) {
        return <Loading />;
    }

    const columns = [
        {
            accessor: (props) => {
                if (!props.meetingDate) return ['-'];

                const date = new Date(props.meetingDate);

                if (!isNaN(date.getTime())) {
                    return [
                        new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        }).format(date).replace(',', '')
                    ];
                } else {
                    return ['-'];
                }
            },
            Header: 'Meeting Date',
            Cell: rowData => {
                const { meetingDate } = rowData.row.original;
                if (meetingDate) {
                    const date = new Date(meetingDate);
                    const formattedDate = new Intl.DateTimeFormat('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    }).format(date)
                        .replace(',', '');
                    return formattedDate;
                } else {
                    return '-';
                }
            }
        },
        {
            accessor: 'meetingLink',
            Header: 'Meeting Link',
            Cell: ({ value }) => {
                if (!value) return '-';
                const url = new URL(value);
                const link = url.hostname.replace(/^www\./, '').replace(/\..*$/, '').charAt(0).toUpperCase() + url.hostname.slice(1).replace(/\..*$/, '');
                return (
                    <a href={value} target="_blank" className="link">
                        {link}
                    </a>
                );
            }
        },
        {
            accessor: (props) => ([props.profile.lastName + ' ' + props.profile.firstName]),
            Header: 'Name',
            Cell: rowData => {
                const { firstName, lastName } = rowData.row.original.profile;
                const name = `${lastName} ${firstName}`
                return name;
            }
        },
        {
            accessor: (props) => ([props.profile.email]),
            Header: 'Email',
            // TODO replace example of advanced cell w/ our own email sending
            Cell: rowData => {
                const { email } = rowData.row.original.profile
                return (
                    <a href={'mailto:' + email}>
                        {email}
                    </a>
                )
            }
        },
        {
            accessor: (props) => ([props.status.name]),
            Header: 'Status',
            Cell: rowData => {
                const { status } = rowData.row.original
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SoftBadge pill bg={getStatusStyling(status).color}>
                            {status.name}
                            <FontAwesomeIcon icon={getStatusStyling(status).icon} className="ms-2" />
                        </SoftBadge>
                    </div>
                )
            }
        },
        {
            accessor: 'description',
            Header: 'Description',
            Cell: ({ cell: { value } }) => {
                const text = value.length > 30 ? value.substring(0, 30) + '...' : value;
                return (
                    <>
                        <Accordion flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <span style={{ fontSize: '80%' }}>{text}</span>
                                </Accordion.Header>
                                <Accordion.Body style={{ overflow: 'hidden', whiteSpace: 'normal' }}>{value}</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                )
            },
        },
        {
            accessor: (props) => ([props?.profile?.company?.name]),
            Header: 'Company',
            Cell: rowData => {
                const { company } = rowData?.row?.original?.profile;
                if (company) {
                    return (
                        <>
                            <Accordion flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <span style={{ fontSize: '80%' }}>{company.name}</span>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <ul key={company.id}>
                                            <li>
                                                <strong>Nume:</strong> {company.name}
                                            </li>
                                            <li>
                                                <strong>Email:</strong> {company.email}
                                            </li>
                                            <li>
                                                <strong>Telefon:</strong> {company.phone}
                                            </li>
                                            <li>
                                                <strong>Industrie:</strong> {company.industry}
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    )
                } else {
                    return ('-')
                }
            }
        },
        {
            accessor: (props) => props.services.map(service => service.name).join(' '),
            Header: 'Services',
            Cell: rowData => {
                const { services } = rowData.row.original;
                return (
                    // TODO either make it a dropdown select to select them on the fly or a modal with the service details. This version is good for now
                    <ul>
                        {services.map((service, index) => (
                            <li key={index}>{service.name}</li>
                        ))}
                    </ul>
                )
            }
        },
        {
            accessor: (props) => {
                const { responsible } = props;
                if (responsible) {
                    return responsible.profile
                        ? `${responsible.profile.lastName} ${responsible.profile.firstName}`
                        : responsible.email;
                } else {
                    return '-';
                }
            },
            Header: 'Responsible',
            Cell: rowData => {
                const { responsible } = rowData.row.original;
                if (responsible) {
                    return responsible.profile
                        ? `${responsible.profile.lastName} ${responsible.profile.firstName}`
                        : responsible.email;
                } else {
                    return '-';
                }
            }
        },
        {
            Header: 'Actions',
            accessor: 'id',
            className: 'actions',
            headerClassName: 'justify-content-center',
            width: 100,
            Cell: (rowData) => (
                <>
                    <Flex justifyContent={'center'} alignContent={'center'} className={'gap-2'}>
                        {/* TODO HIDE BUTTON IF NO FILES TO DOWNLOAD */}
                        {(rowData.row.original.files && rowData.row.original.files.length) ? (
                            <ActionButton icon="cloud-download-alt" title="Download" variant="action" className="btn-xs btn-falcon-success" onClick={() => handleFilesDownload(rowData.row.original.id)} />
                        ) : ('')}
                        <ActionButton icon="edit" title="Edit" variant="action" className="btn-xs btn-falcon-primary" onClick={() => handleEdit(rowData.row.original.id)} />
                        <ActionButton icon="trash" title="Delete" variant="action" color="red" className="btn-xs btn-falcon-primary" onClick={() => handleDelete(rowData.row.original.id)} />
                        <ActionButton icon="check-square" title="Confirm" variant="action" color="#00d27a" className="btn-xs btn-falcon-primary" onClick={() => handleConfirm(rowData.row.original.id)} />
                        <ActionButton icon="times" title="Refuse" variant="action" color="red" className="btn-xs btn-falcon-primary" onClick={() => handleRefuse(rowData.row.original.id)} />
                    </Flex>
                </>
            ),
        },
    ];

    return (
        <>
            <BreadCrumb current={'Leads'} />
            {/* Table */}
            <Card>
                <Card.Body>
                    <AdvanceTableWrapper
                        columns={columns}
                        data={state.leads || []}
                        sortable
                        pagination
                        perPage={5}
                    >
                        <Row className="flex-end-center mb-3">
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                        <TableActionButtons exportBtn={true} handleExportClick={handleExportClick} />
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                        {state?.leads?.length ? (
                            <div className="mt-3">
                                <AdvanceTableFooterKerrsid
                                    rowCount={state?.leads?.length ?? 0}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </div>
                        ) : ''}
                    </AdvanceTableWrapper>
                </Card.Body>
            </Card>
            {/* Table */}

            {/* Modals */}
            {state.lead && state.statuses && state.isOpenModal && (
                <LeadsModal
                    show={state.isOpenModal}
                    lead={state.lead}
                    responsibleUsers={state.responsibleUsers}
                    statuses={state.statuses}
                    onHide={() => dispatch({ type: 'CLOSE_MODAL' })}
                    onUpdateLead={onUpdateLead}
                />
            )}
            {state.isInfoModalOpen && (
                <InfoModal
                    show={state.isInfoModalOpen}
                    title={'Coming Soon!'}
                    message={'This feature is not yet implemented and is coming soon!'}
                    onHide={() => dispatch({ type: 'CLOSE_INFO_MODAL' })}
                />
            )}
            {state.lead !== null && state.isOpenConfirmationModal && (
                <>
                    <ConfirmationModal
                        show={state.isOpenConfirmationModal}
                        title={state.confirmationTitle}
                        message={state.confirmationMessage}
                        onClose={(result) => handleConfirmation(state.confirmationType, result)}
                    />
                </>
            )}
            {/* Modals */}

        </>
    );
};

export default Leads;

import React from 'react';
import ProfileBanner from './Banner';
import ProfileIntro from './ProfileIntro';
import { Col, Row } from 'react-bootstrap';
import activities from 'data/activities';
import ActivityLog from './ActivityLog';
import Events from './Events';
import events from 'data/events/events';

const Profile = () => {
  return (
    <>
      <ProfileBanner />
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ProfileIntro />
          <ActivityLog className="mt-3" activities={activities.slice(5, 9)} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <Events
              className="mb-3"
              cardTitle="Events"
              events={events.slice(2, 5)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Profile;

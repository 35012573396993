import React, { useRef, useState, useEffect } from 'react';
import Section from 'components/common/Section';
import { Button, Col, Dropdown, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import SectionHeader from './SectionHeader';
import { Controller, useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import ro from 'date-fns/locale/ro'
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import { getCsrfToken, getSize } from 'helpers/utils';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import classNames from 'classnames';
import { sendRecaptchaToken, sendLead } from 'api/contactForm';
import { toast } from 'react-toastify';
import PNotify from 'components/common/PNotify/PNotify';
import CardService from './CardService';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import bg3 from 'assets/img/illustrations/corner-4-reverse.png';
import Select from 'react-select';
import { addDays } from '@fullcalendar/react';
import { getServices } from 'api/services';
import MaskedInput from 'react-text-mask';
registerLocale('ro', ro);

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const [datePicker, setDatePicker] = useState(null);
  const [datePickerError, setDatePickerError] = useState(false);
  const [multiSelectError, setMultiSelectError] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [formData, setData] = useState({});
  const [recaptchaChecked, setRecaptchaChecked] = useState(false)
  const [showRecaptchaError, setShowRecaptchaError] = useState(false);
  const captchaRef = useRef(null);
  const [services, setServices] = useState(null);
  const [isBusiness, setIsBusiness] = useState(false);

  // const htmlSelfEmployed= `<div class="mb-3"><label class="form-label" for="vatNumber">Cod Fiscal</label><input name="vatNumber" placeholder="RO450XXXXX" type="text" id="vatNumber" class="form-control"></div><div class="mb-3"><label class="form-label">Selecteză o dată și o oră</label><div class="react-datepicker-wrapper"><div class="react-datepicker__input-container"><input type="text" placeholder="Programeză o întâlnire" class="form-control" required="" value=""></div></div></div>`;
  const isWeekday = (datePicker) => {
    const day = datePicker.getDay();
    return day !== 0 && day !== 6;
  };

  const calculateBusinessDays = (startDate, days) => {
    let currentDate = startDate;
    let businessDaysRemaining = days;

    while (businessDaysRemaining > 0) {
      currentDate = addDays(currentDate, 1);

      if (isWeekday(currentDate)) {
        businessDaysRemaining--;
      }
    }
    return currentDate;
  };

  const initialMinDate = calculateBusinessDays(setHours(setMinutes(new Date(), 0), 10), 3);

  const onSubmit = async data => {
    setData(data);
    const csrfToken = await getCsrfToken();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    const recaptchaData = { token: token }
    const getRecaptchaStatus = await sendRecaptchaToken(recaptchaData, csrfToken);

    if (!datePicker || !recaptchaChecked || getRecaptchaStatus?.data?.error) {
      if (!datePicker) {
        setDatePickerError(true);
      }

      if (!recaptchaChecked) {
        setShowRecaptchaError(true);
      }
    } else {
      setDatePickerError(false)
      const base64Files = {};
      for (const [key, file] of Object.entries(files)) {
        base64Files[key] = {
          path: file.path,
          size: file.size,
          type: file.type,
          name: file.name,
          base64: await getBase64(file) ?? '',
        };
      }
      const body = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        meetingDate: new Date(datePicker).toLocaleDateString() + ' ' + new Date(datePicker).toLocaleTimeString(),
        description: data.description,
        companyName: data.companyName,
        companyEmail: data.companyEmail,
        companyPhone: data.companyPhone,
        companyIndustry: data.companyIndustry,
        services: data.services.map(service => service.value).join(','),
        files: base64Files,
        isMarketing: data.isMarketing,
        acceptedTerms: data.acceptedTerms
      }
      let contactFormDataStatus = await sendLead(body, csrfToken);
      if (contactFormDataStatus?.status === 200 && contactFormDataStatus.data.error === 0) {
        reset();
        setDatePicker(null);
        setDatePickerError(false);
        setFiles([]);
        return toast.success(
          <PNotify
            title="Success"
            icon="fas fa-check"
            text={contactFormDataStatus.data.message}
          />,
          {
            containerId: "default",
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      } else {
        return toast.error(
          <PNotify
            title="Error"
            icon="fas fa-check"
            text={contactFormDataStatus.data.message}
          />,
          {
            containerId: "default",
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      }
    }
  };

  const onChangeReCAPTCHA = () => {
    setRecaptchaChecked(true);
    setShowRecaptchaError(false);
  }

  const openNewWindow = (url) => {
    window.open(url);
  };

  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64Data = base64String.replace(/^data:.*?;base64,/, '');
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.pdf',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  const [scaleFactor, setScaleFactor] = useState(1);

  useEffect(() => {
    function handleResize() {
      const maxScale = 1.0;

      const screenWidth = window.innerWidth;
      const scaleFactor = Math.min(
        maxScale,
        Math.max(screenWidth / 570)
      );

      setScaleFactor(scaleFactor);
    }

    async function getServicesAsync() {
      let csrfToken = await getCsrfToken();
      let result = await getServices(csrfToken);
      if (result.status === 200) {
        let services = result.data.message.map((option) => {
          return { value: option.id, label: option.name };
        });
        setServices(services);
      } else {
        setServices([]);
      }
    }

    // Add event listener to window object
    window.addEventListener('resize', handleResize);
    handleResize();

    if (!services && services != []) {
      getServicesAsync();
    }

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [services]);

  return (
    <Section image={bg3} bg="light" id="contact">
      <SectionHeader
        title="Hai să ne cunoaștem!"
        subtitle="Contact"
      />
      <Row className="mt-6 mt-lg-6 flex-center">
        <Col xs={10} lg={9} xl={8}>
          <CardService media={{ icon: faHandshake, color: 'primary', className: 'fs-4' }} title=''>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" value="abcdef" />
              <Row className="mb-1 g-2">
                <Form.Group as={Col} controlId="firstName">
                  <FloatingLabel controlId="floatingInput" label="Nume" className="mb-3">
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="Numele dvs."
                      isInvalid={!!errors.firstName}
                      {...register('firstName', {
                        required: "Acest câmp este necesar!",
                        validate: value =>
                          value.length > 2 || 'Numele trebuie să conțină mai mult de 2 caractere!'
                      })}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName && errors.firstName.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                  <FloatingLabel controlId="floatingInput" label="Prenume" className="mb-3">
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Prenumele dvs."
                      isInvalid={!!errors.lastName}
                      {...register('lastName', {
                        required: "Acest câmp este necesar!",
                        validate: value =>
                          value.length > 2 || 'Prenumele trebuie să conțină mai mult de 2 caractere!'
                      })}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName && errors.lastName.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mb-1 g-2'>
                <Form.Group as={Col} controlId="email">
                  <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="nume@example.com"
                      isInvalid={!!errors.email}
                      {...register('email', {
                        required: "Acest câmp este necesar!",
                        validate: value =>
                          value.includes("@") || 'În acest câmp trebuie să includă simbolul "@"!'
                      })}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className=' mb-3 g-2'>
                <Form.Group as={Col} controlId="datePicker">
                  <ReactDatePicker
                    selected={datePicker ?? initialMinDate}
                    locale="ro"
                    name="datePicker"
                    onChange={datePicker => setDatePicker(datePicker)}
                    formatWeekDay={day => day.slice(0, 3)}
                    minDate={initialMinDate}
                    minTime={setHours(setMinutes(new Date(), 0), 10)}
                    maxTime={setHours(setMinutes(new Date(), 59), 16)}
                    className={classNames('form-control', {
                      'is-invalid': datePickerError,
                    })}
                    placeholderText="Programeză o întâlnire"
                    timeIntervals={30}
                    filterDate={isWeekday}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                </Form.Group>
              </Row>
              <Row className='g-2'>
                <Form.Group as={Col} controlId="description">
                  <FloatingLabel controlId="floatingTextArea" label="Descriere" className="mb-3">
                    <Form.Control
                      as="textarea"
                      name="description"
                      style={{ height: '100px' }}
                      placeholder="Descriere"
                      isInvalid={!!errors.description}
                      {...register('description', {
                        required: "Acest câmp este necesar!",
                        validate: value =>
                          value.length > 9 || 'Descrierea trebuie să conțină mai mult de 10 caractere!'
                      })}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {errors.description && errors.description.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='g-2'>
                <Form.Group>
                  <Form.Check
                    inline
                    name='personType'
                    type='radio'
                    id='bc'
                    label='Persoană Fizică'
                    defaultChecked
                    onClick={() => setIsBusiness(false)}
                  />
                  <Form.Check
                    inline
                    name='personType'
                    type='radio'
                    id='bb'
                    label='Persoană Juridică'
                    onClick={() => setIsBusiness(true)}
                  />
                </Form.Group>
              </Row>
              {
                isBusiness && (
                  <>
                    <hr />
                    <Row className="mb-1 g-2">
                      <Form.Group as={Col} controlId="companyName">
                        <FloatingLabel controlId="floatingInput" label="Denumire Companie" className="mb-3">
                          <Form.Control
                            type="text"
                            name="companyName"
                            placeholder="Denumire Companie"
                            isInvalid={!!errors.companyName}
                            {...register('companyName', {
                              required: "Acest câmp este necesar!",
                              validate: value =>
                                value.length > 2 || 'Numele trebuie să conțină mai mult de 2 caractere!'
                            })}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.companyName && errors.companyName.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="companyEmail">
                        <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
                          <Form.Control
                            type="text"
                            name="companyEmail"
                            placeholder="Email"
                            isInvalid={!!errors.companyEmail}
                            {...register('companyEmail', {
                              required: "Acest câmp este necesar!",
                              validate: value =>
                                value.includes("@") || 'În acest câmp trebuie să includă simbolul "@"!'
                            })}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.companyEmail && errors.companyEmail.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className='mb-1 g-1'>
                      <Form.Group as={Col} controlId="companyPhone">
                        <FloatingLabel controlId="floatingInput" label="&#9742; Telefon" className="mb-3 show-placeholder">
                          <Controller
                            name="companyPhone"
                            control={control}
                            as={FloatingLabel}
                            defaultValue=""
                            render={({ field }) => (
                              <MaskedInput
                                mask={[
                                  '+', '4', /\d/, ' ', /\d/, /\d/, /\d/, ' ',
                                  /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/
                                ]}
                                className='form-control'
                                guide={true}
                                placeholder="+40 XXX XXX XXX"
                                id="companyPhone"
                                {...field}
                              />
                            )}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.companyPhone && errors.companyPhone.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className='g-2'>
                      <Form.Group as={Col} controlId="companyIndustry">
                        <FloatingLabel controlId="floatingTextArea" label="Industrie" className="mb-3">
                          <Form.Control
                            as="textarea"
                            name="companyIndustry"
                            style={{ height: '100px' }}
                            placeholder="Descriere"
                            isInvalid={!!errors.companyIndustry}
                            {...register('companyIndustry', {
                              required: "Acest câmp este necesar!",
                              validate: value =>
                                value.length > 9 || 'Câmpul trebuie să conțină mai mult de 10 caractere!'
                            })}
                          />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                          {errors.companyIndustry && errors.companyIndustry.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <hr />
                  </>
                )
              }
              <Row className='mb-3 g-2'>
                <Form.Group controlId='services'>
                  <Controller
                    control={control}
                    name="services"
                    rules={{ required: 'Field required' }}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        closeMenuOnSelect={false}
                        options={services}
                        placeholder='Select...'
                        isMulti
                        classNamePrefix="react-select"
                        value={value ? value : []}
                        onChange={onChange}
                        className={classNames({
                          'is-invalid border border-danger rounded': errors.services ? true : false,
                        })}
                      />
                    )}
                  />
                  {errors.services && (
                    <div className="invalid-feedback">
                      {errors.services.message}
                    </div>
                  )}
                </Form.Group>
              </Row>
              <div {...getRootProps({ className: 'dropzone-area py-2 m-1 is-invalid' })}>
                <input {...getInputProps()} id='files' />
                <img src={cloudUpload} alt="" width={50} className="me-2" />
                <p className="fs-0 mb-0 text-700">Apasă aici și selecteză fișerele sau trage-le cu mouse-ul în această zonă.</p>
                <p className="fs-0 mb-0 text-700">Adăugați fișiere, cu o limită de 20 MB, documente pdf, docx, xls, sau imagine cu următoarele extensii .jpeg, .jpg, .png</p>
              </div>
              <div className="mt-3 mb-3">
                {files.map(file => (
                  <Flex
                    alignItems="center"
                    className="py-3 border-bottom btn-reveal-trigger"
                    key={file.path}
                  >
                    <Image rounded width={40} height={40} src={file.preview} alt={file.path} />
                    <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                      <div>
                        <h6>{file.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>
                              {getSize(file.size)}
                            </strong>
                          </p>
                        </Flex>
                      </div>
                    </Flex>
                    <CardDropdown>
                      <div className="py-2">
                        <Dropdown.Item className="text-danger" onClick={() => handleRemove(file.path)}>
                          Remove
                        </Dropdown.Item>
                      </div>
                    </CardDropdown>
                  </Flex>
                ))}
              </div>
              <Row className='mb-1 g-1'>
                <Col style={{ transform: `scale(${scaleFactor})`, transformOrigin: '0 0' }}>
                  <ReCAPTCHA className='mb-3'
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={onChangeReCAPTCHA}
                    ref={captchaRef}
                  />
                </Col>
              </Row>
              {showRecaptchaError && (
                <div style={{ color: 'red', marginBottom: '1rem' }}>Please check the reCAPTCHA field!</div>
              )}
              <Form.Group className="mb-1" controlId="acceptedTerms">
                <Col className='d-flex'>
                  <Form.Check
                    type="checkbox"
                    name="acceptedTerms"
                    isInvalid={!!errors.acceptedTerms}
                    {...register('acceptedTerms', {
                      required: "Acest câmp este necesar!"
                    })}
                  />
                  <Form.Label
                    style={{
                      marginLeft: "0.5rem",
                      marginTop: "0.125rem"
                    }}
                  >
                    Confirm că am citit și accept&nbsp;
                    <span
                      style={{
                        color: "#2c7be5",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}
                      onMouseOver={(e) => (e.target.style.color = "#2362b7", e.target.style.textDecoration = "underline")}
                      onMouseOut={(e) => (e.target.style.color = "#2c7be5", e.target.style.textDecoration = "none")}
                      onClick={() => openNewWindow("/terms-and-conditions")}
                    >
                      Termenii și Condițiile.
                    </span>
                  </Form.Label>
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.acceptedTerms && errors.acceptedTerms.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2" controlId="isMarketing">
                <Col className='d-flex'>
                  <Form.Check
                    type="checkbox"
                    name="isMarketing"
                    isInvalid={!!errors.isMarketing}
                    {...register('isMarketing')}
                  />
                  <Form.Label style={{ marginLeft: "0.5rem", marginTop: "0.125rem" }}>Fii la curent cu cele mai noi oferte și noutăți! Abonează-te la Newsletterul nostru!</Form.Label>
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.isMarketing && errors.isMarketing.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit">
                Trimite
              </Button>
            </Form>
          </CardService>
        </Col>
      </Row>
    </Section>
  );
};

export default ContactForm;
